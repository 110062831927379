import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup

const name = 'Erin Nielsen Ogdahl'
const license = 'JD, CSW-PIP, LAC'
const areas = [
    'Substance Use Disorders​',
    'Family Issues',
    'Custody Evaluations',
    'Parenting Coordination',
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./erin-nielsen-ogdahl.jpg' alt={name} layout="constrained" className="shadow" />)}
        >

<p>Erin completed her bachelor's of social work at Colorado State University in 1998.  She completed her master's degree in Social Work at the University of Denver in 2003.  She started her career working in residential treatment with adolescents struggling with addiction. From there she spent a few years working in probation services and in drug courts.</p>
<p>Erin then returned to South Dakota and graduated from the University of South Dakota Law School in 2006.  After law school, Erin practiced family law for two years before going back into the therapeutic field.</p>
<p>Coming from a background as a chemical dependency counselor, social worker, and family law attorney, Erin is uniquely qualified to provide the necessary expertise, objectivity, and authority required in your case. At this point in her career, Erin would like to bring together her education and experiences and offer a variety of services that help individuals and families who are struggling.   </p>
<p>Services offered: Individual Therapy,  Custody Evaluations/Home Studies, and Parenting Coordination.</p>
<p>To schedule an appointment or receive more information on my qualifications or services, please contact me at <a href="tel:1-605-271-1348">605.271.1348</a> or <a href="mailto:Erin@IntegrativeWellnessSD.com">Erin@IntegrativeWellnessSD.com</a></p>


        </Therapist>
    </Layout>
)

export default IndexPage
